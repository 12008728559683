.headroom-wrapper {
    margin-bottom: 1em;
}

Header {
    padding-block-start: 0.3em;
    padding-block-end: 0.3em;
    background-color: var(--background-colour);
    padding-bottom: 0.3em;
}

.header-name {
    text-align: left;
    align-content: center;
    font-size: xx-large;
}

.header-tabs {
    color: var(--theme-colour);
    padding-bottom: 0.4em;
    padding-top: 0.5em;
}

.header-tabs a {
    text-decoration: none;
}

.header-tab {
    opacity: 0.5;
    margin-left: 2em;
    width: 20em;
    padding-bottom: inherit;
    padding-top: inherit;
    border-bottom-width: 0.2em;
    border-bottom-color: var(--theme-colour);
    border-bottom-style: solid;
}

.header-menu-button {
    float: right;
}

.header-tab.active {
    opacity: 1;
}

#header-bar {
    width: 100%;
    height: 0.1em;
    padding-bottom: 0.2em;
    background:var(--theme-colour);
    margin: auto;
    margin-bottom: 0.3em;
}

.theme-switch {
    margin-left: 0.5em;
    margin-top: 0.15em;
    vertical-align: top;
}

.header .theme-switch {
    float: right;
}

.theme-switch-icon {
    /* Calculated with https://codepen.io/sosuke/pen/Pjoqqp */
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(147deg) brightness(103%) contrast(102%);
    padding-top: 2px;
    padding-left: 2px;
}

.media-widget {
    font-size: xx-large;
    padding-left: 0.2em;
    height: 1em;
}

.header .media-widgets {
    float: right;
}

@media print {
    .headroom-wrapper {
        display: none;
    }
}