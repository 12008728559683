.nes-game {
    margin: 1em auto;
    width: 100%;
    text-align: center;
    vertical-align: text-top;
}

.nes-game-items {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    margin: auto;
}

.nes-game-scale-buttons {
    height: 100px;
    border-style: dashed;
    border-color: var(--theme-colour);
    border-left: #00000000;
    border-bottom: #00000000;
    color: var(--theme-colour);
}

.nes-game-scale-header {
    font-size: 24px;
    padding: 5px;
}

.nes-game-scale-button {
    width: 33%;
    height: 60%;
    color: var(--theme-colour);
    border-color: var(--theme-colour);
    border-image: none;
    border-style: solid;
    background-color: rgba(0, 171, 68, 0.25);
    font-size: 24px;
}

.nes-game-scale-button:hover {
    background-color: rgba(0, 171, 68, 0.40);
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.nes-rom-upload-area {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 171, 68, 0.25);
    height: 140px;
    width: 256px;
    border-style: dashed;
    border-color: var(--theme-colour);
    border-left: #00000000;
}

.nes-rom-upload-area:hover {
    background-color: rgba(0, 171, 68, 0.40);
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.nes-rom-upload-text {
    color: var(--theme-colour);
    font-size: 16px;
}

.nes-canvas-wrapper {
    display: inline-block;
    margin-left: 256px;
}

#nes-canvas {
    border: var(--theme-colour);
    border-style: solid;
    border-width: 5px;

    image-rendering: optimizeSpeed;
    image-rendering: -moz-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: optimize-contrast;
    image-rendering: pixelated;
    -ms-interpolation-mode: nearest-neighbor;
}