.home-page {
    font-size: 1.1em;
}

.resume input {
    font-family: 'Lato', sans-serif;
    font-size: 1em;
    color: var(--primary-text-colour);
    background-color: var(--background-colour);
    border-color: var(--theme-colour);
    border-style: groove;
    width: 90%;
}

.resume-contact-info {
    display: none;
}

.resume-contact {
    color: var(--secondary-text-colour);
}

#resume-header-bar {
    width: 100%;
    height: 0.1em;
    padding-bottom: 0.2em;
    background: var(--theme-colour);
    -webkit-print-color-adjust: exact;
    margin-bottom: 10px;
}

.resume-control-header {
    color: var(--theme-colour);
    font-weight: bolder;
    text-align: left;
    width: 40%;
}

.resume-control {
    width: 60%;
    display: inline-block;
}

.react-autocomplete-input {
    background-clip: padding-box;
    background-color: var(--background-colour);
    border-top: 1px solid rgba(0,0,0,0.15);
    bottom: auto;
    box-shadow: 0 6px 12px rgba(0,0,0,0.175);
    list-style: none;
    padding: 1px;
    position: absolute;
    z-index: 10;
}

.react-autocomplete-input > li {
    cursor: pointer;
    padding: 10px;
    min-width: 100px;
}

.react-autocomplete-input > li.active {
    background-color: var(--theme-colour);
    color: #fff;
}

.horizontal-slider {
    max-width: 60%;
    position: relative;
}

.slider-thumb {
    width: 20px;
    height: 20px;
    top: -7px;
    box-shadow: #999999;
    border-radius: 50%;
    position: absolute;
    background: var(--theme-colour);
}

.slider-track {
    /*top: 7px;*/
    height: 6px;
    border-radius: 5px;
}

.slider-track-0 {
    background: var(--theme-colour);
}

.slider-track-1 {
    background: var(--secondary-text-colour);
}

@media print {
    .home-info, .resume-controls, .resume > h1, .resume > p {
        display: none;
    }

    .resume-contact-info {
        display: block;
    }
}