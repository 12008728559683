:root {
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--primary-text-colour);
  background-color: var(--background-colour);

  /* Variables */
  --primary-text-colour:  rgb(53, 55, 68);
  --secondary-text-colour: rgb(102,102,102);
  --theme-colour: rgb(0, 171, 68);
  --background-colour: rgb(255, 255, 255);
  --text-max-width: 1600px;
}

.dark-mode {
  color: rgb(255, 255, 255);
  --primary-text-colour:  rgb(255, 255, 255);
  --secondary-text-colour: rgb(153, 153, 153);
  --background-colour: rgb(18, 18, 18);
}

.menu-button {
  height: 37px;
  /* Light Mode Filter */
  filter: invert(18%) sepia(17%) saturate(606%) hue-rotate(194deg) brightness(96%) contrast(88%);
}

.dark-mode .menu-button {
   /* Dark Mode Filter */
  filter: invert(99%) sepia(7%) saturate(277%) hue-rotate(180deg) brightness(113%) contrast(100%) !important;
}

a {
  color: inherit;
}

html {
  min-height: 100% !important;
  overflow-y: scroll !important;
}    

body {
   min-height: 100% !important;
   padding-bottom: 20px;
}

html, body, #root, .app {
  margin: 0;
  min-height: 100%;
  background: var(--background-colour);
}

h1, h2 {
  color: var(--theme-colour);
  font-weight: 700;
}

h3 {
  color: var(--primary-text-colour);
  font-weight: 700;
  font-size: 1.2em;
}

h4 {
  font-size: 1.2em;
  color: var(--secondary-text-colour);
  font-weight: 400;
  font-style: italic;
}

h6 {
  font-size: 1em;
  margin-top: 0.2em;
  margin-bottom: 0.2em;
  color: var(--secondary-text-colour);
  font-weight: 400;
}

.inline {
  display: inline;
}

._loading_overlay_overlay {
  background: var(--background-colour) !important;
}

._loading_overlay_wrapper {
  min-height: 100%;
}

.page {
  margin: auto;
  max-width: var(--text-max-width);
}

.margin {
  width: 90%;
  margin: auto;
}

.drop {
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media print {
  :root {
    font-size: 11pt;
  }

  .dark-mode {
    --primary-text-colour:  var(--primary-text-colour);
    --secondary-text-colour: var(--secondary-text-colour);
    --theme-colour: var(--theme-colour);
    --background-colour: var(--background-colour);
  }

  h1 {
    font-size: 15pt;
    margin: 0;
  }

  h3 {
    font-size: 12pt;
    margin: 0;
  }

  ul {
    margin: 10px;
  }

  a {
    text-decoration: none;
  }
}