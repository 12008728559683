.git-card {
    text-align: left;
    width: 80%;
    height: 8em;
    border-style: double;
    border-color: var(--theme-colour);
    padding-top: 0.2em;
    margin: auto;
}

a.git-link {
    text-decoration: none;
}

.git-info {
    margin-block-end: 2em;
}

.git-date {
    float: right;
    font-style: italic;
}

.git-card-pad {
    padding: 1em;
}

.git-card-header{
    padding-bottom: 0.3em;
}

.git-panel {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: normal;
    row-gap: 1em;
}

@media only screen and (max-width: 1100px) {
    .git-panel {
        grid-template-columns: auto;
    }

    .git-card {
        width: 100%;
        height: fit-content;
    }
}